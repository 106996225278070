import { useMutation, useQuery } from '@apollo/react-hooks';
import { faTimes, faUserCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Link from 'next/link';
import Router from 'next/router';
import React, { useState } from 'react';

import { LOCAL_LOGOUT } from '../lib/mutations';
import { GET_ME } from '../lib/queries';
import routes from '../lib/routes';
import t from '../lib/translations';
import { Modal } from './BasicModal';

const items = [
  { href: routes.HOME, title: t.HOME },
  { href: routes.TRANSACTION, title: t.GENERATE_QR_CODE },
  { href: routes.EXTRACT, title: t.EXTRACT },
  { href: routes.SETTINGS, title: t.SETTINGS },
];

const SidebarResponsive = ({ onToggle }: { readonly onToggle?: () => void }): JSX.Element => {
  const { data } = useQuery(GET_ME);

  const [localLogout] = useMutation(LOCAL_LOGOUT);
  const asPath = Router?.router?.asPath;
  const [show, setShow] = useState(false);
  const handleClose = (): any => setShow(false);
  const handleShow = (): any => setShow(true);
  const shouldChangePassword = data?.me?.shouldChangePassword;

  const logout = async (): Promise<void> => {
    handleClose();
    await localLogout();
  };

  return (
    <>
      {/* Desktop Sidebar */}
      <div id="sidebar" className="w-80 h-screen bg-dark float-left hidden sm:block">
        <ul>
          <li className="flex flex-row items-center justify-evenly text-whiteOne font-thin px-2 py-5 bg-gradient-to-b from-gray-eclipse via-gray-eclipse to-brown border-b border-whiteOne">
            <div className="w-10 h-10">
              <FontAwesomeIcon icon={faUserCircle} size="3x" color="#d7e2de" />
            </div>

            <div className="flex flex-col">
              <div className="text-xl font-semibold">{data?.me?.partner?.administrator}</div>
              <div className="text-lg font-semibold">{`ID: ${data?.me?.id
                .split('-')[0]
                .toUpperCase()}`}</div>
            </div>
          </li>

          <div className="px-6">
            {items.map((item) => (
              <li
                key={item.href}
                style={shouldChangePassword ? { pointerEvents: 'none', opacity: '0.6' } : {}}>
                <Link href={item.href}>
                  <a
                    className={`block py-5 pl-1 align-middle no-underline  border-whiteOne ${
                      item.href === asPath ? 'border-b-2' : 'border-b'
                    }`}>
                    <span
                      className={`font-semibold ${
                        item.href === asPath ? 'text-2xl text-white' : 'text-lg text-whiteOne'
                      }`}>
                      {item.title}
                    </span>
                  </a>
                </Link>
              </li>
            ))}
            <li
              className="block py-3 pl-1 align-middle text-gray-100 no-underline border-b-2 border-gray-800"
              onClick={handleShow}>
              <span className="text-gray-300 text-lg"> {t.LOGOUT}</span>
            </li>
          </div>
        </ul>
      </div>

      {/* Mobile Sidebar */}
      <div className="fixed overflow-hidden z-10 block sm:hidden">
        <div className="absolute inset-0 overflow-hidden">
          <div className="absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />

          <div className="fixed inset-y-0 left-0 pr-10 max-w-full flex">
            <div className="w-screen max-w-md">
              <div className="h-full flex flex-col pt-6 bg-gradient-to-b from-green-laurel to-greenFour shadow-xl overflow-y-scroll">
                <div className="px-4 sm:px-6">
                  <div className="flex items-start justify-between">
                    <div className="text-link-white w-auto flex flex-row">
                      <img
                        alt="logo"
                        src="/logo.png"
                        className="inline-block align-top mr-4 h-12 w-12"
                      />
                      <div className="flex flex-col">
                        <div className="flex flex-inline">
                          <span className="text-xl font-semibold text-dark">
                            {t.PRODUTOR_TITLE}
                          </span>
                          <span className="text-xl font-semibold text-orange-chocolate m-0">
                            {t.CARD_TITLE}
                          </span>
                        </div>
                        <span className="text-sm font-semibold text-green-eveningSea">
                          {t.PARTNER}
                        </span>
                      </div>
                    </div>

                    <div className="ml-3 mt-1 h-7 flex items-center">
                      <button
                        onClick={onToggle}
                        className="rounded-md text-gray-100 hover:text-gray-500 focus:outline-none">
                        <FontAwesomeIcon
                          className="h-6 w-6"
                          icon={faTimes}
                          size="2x"
                          color="#2d353c"
                        />
                      </button>
                    </div>
                  </div>
                </div>
                <div className="mt-6 relative flex-1 bg-gray-cod">
                  <ul>
                    <li className="flex flex-row items-center text-whiteOne font-thin pl-6 py-5 bg-gradient-to-b from-gray-eclipse via-gray-eclipse to-brown border-b border-whiteOne">
                      <div className="w-10 h-10">
                        <FontAwesomeIcon icon={faUserCircle} size="3x" color="white" />
                      </div>

                      <div className="flex flex-col">
                        <div className="text-xl font-semibold">
                          {data?.me?.partner?.administrator}
                        </div>
                        <div className="text-lg font-semibold">{`ID: ${data?.me?.id
                          .split('-')[0]
                          .toUpperCase()}`}</div>
                      </div>
                    </li>

                    <div className="px-6">
                      {items.map((item) => (
                        <li
                          key={item.href}
                          style={
                            shouldChangePassword ? { pointerEvents: 'none', opacity: '0.6' } : {}
                          }>
                          <Link href={item.href}>
                            <a
                              onClick={onToggle}
                              className={`block py-5 pl-1 align-middle no-underline  border-whiteOne ${
                                item.href === asPath ? 'border-b-2' : 'border-b'
                              }`}>
                              <span
                                className={`font-semibold ${
                                  item.href === asPath
                                    ? 'text-2xl text-white'
                                    : 'text-lg text-whiteOne'
                                }`}>
                                {item.title}
                              </span>
                            </a>
                          </Link>
                        </li>
                      ))}
                      <li
                        className="block py-3 pl-1 align-middle text-whiteOne no-underline border-b border-WhiteOne"
                        onClick={handleShow}>
                        <span className="text-gray-300 text-lg"> {t.LOGOUT}</span>
                      </li>
                    </div>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {show && (
        <Modal
          id="logout-modal"
          title={t.info.CONFIRM_LOGOUT}
          leftButtonLabel={t.CONFIRM}
          rightButtonLabel={t.CANCEL}
          onClose={handleClose}
          leftButtonAction={logout}
          rightButtonAction={handleClose}
        />
      )}
    </>
  );
};

export default SidebarResponsive;
