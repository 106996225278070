import React, { ReactNode } from 'react';

import { Button } from '../components/Button';

export type ModalProperties = {
  readonly id?: string;
  readonly title?: string;
  readonly children?: ReactNode;
  readonly rightButtonLabel?: string;
  readonly leftButtonLabel?: string;
  readonly disabled?: boolean;
  readonly icon?: ReactNode;
  readonly bgIcon?: string;
  readonly rightButtonAction?: () => void;
  readonly leftButtonAction?: () => void;
  readonly onClose?: (value?: any) => void;
};

export const Modal: React.FC<ModalProperties> = ({
  id,
  title,
  children,
  rightButtonLabel,
  leftButtonLabel,
  disabled,
  icon,
  bgIcon = 'bg-gray-100',
  rightButtonAction,
  leftButtonAction,
  onClose,
}) => {
  return (
    <div
      id={id}
      className="fixed z-20 inset-0 overflow-y-auto"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true">
      <div className="items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center block">
        <div
          className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
          aria-hidden="true"
        />

        <span className="hidden sm:inline-block align-middle sm:h-screen" aria-hidden="true">
          &#8203;
        </span>

        <div className="inline-block bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
          <div className="block absolute top-0 right-0 pt-4 pr-4" onClick={onClose}>
            <button
              type="button"
              className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-1 focus:ring-offset-2 focus:ring-gray-400">
              <span className="sr-only">Close</span>
              <svg
                className="h-6 w-6"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true">
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
          <div className="sm:flex sm:items-start">
            <div className="w-full mt-6 text-center">
              <h3 className="text-lg leading-6 font-medium text-gray-900" id="modal-title">
                {title}
              </h3>
              <div className="mt-2">{children}</div>
            </div>
          </div>
          <div className="mt-5 w-full inline-flex justify-center">
            {leftButtonAction && (
              <Button
                id="modal-left-btn"
                disabled={disabled}
                onClick={leftButtonAction}
                type="button"
                color="gray"
                className="hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500">
                {leftButtonLabel}
              </Button>
            )}
            {rightButtonAction && (
              <Button
                id="modal-right-btn"
                disabled={disabled}
                onClick={rightButtonAction}
                type="button"
                color="teal"
                className="ml-2">
                {rightButtonLabel}
              </Button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
