import React from 'react';

import { IconProps } from '../../lib/types';

const BurguerMenuIcon = ({
  width = 'w-6',
  height = 'h-6',
  color = 'text-gray-200',
  onClick,
  styles,
}: IconProps): JSX.Element => {
  return (
    <svg
      className={`${height} ${width} ${color} ${styles} ${onClick ? 'cursor-pointer' : ''} `}
      viewBox="0 0 20 20"
      fill="currentColor">
      <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
    </svg>
  );
};

export default BurguerMenuIcon;
