import { useMutation, useQuery } from '@apollo/react-hooks';
import React from 'react';

import { SET_SIDEBAR } from '../lib/mutations';
import { IS_SIDEBAR_OPEN } from '../lib/queries';
import HeaderResponsive from './HeaderResponsive';
import SidebarResponsive from './SidebarResponsive';

const ContainerResponsive = ({ children }: any): JSX.Element => {
  const { data: sidebarData } = useQuery(IS_SIDEBAR_OPEN);
  const [setSideBar] = useMutation(SET_SIDEBAR);

  const onToggle = (): void => {
    setSideBar({ variables: { open: !sidebarData?.isSidebarOpen } });
  };

  return (
    <div className="flex flex-col">
      <HeaderResponsive onToggle={onToggle} />

      <div className="flex flex-row h-screen w-screen">
        {sidebarData?.isSidebarOpen && <SidebarResponsive onToggle={onToggle} />}

        <div className="h-nav flex flex-row justify-center w-full py-2 overflow-scroll">
          {children ?? children}
        </div>
      </div>
    </div>
  );
};

export default ContainerResponsive;
