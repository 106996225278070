import React from 'react';

import t from '../lib/translations';
import BurguerMenuIcon from './icons/BurguerMenuIcon';

const HeaderResponsive = ({ onToggle }: { readonly onToggle: () => void }): JSX.Element => {
  return (
    <nav className="bg-gradient-to-b from-green-laurel to-greenFour py-4">
      <div className="max-w-7xl px-8">
        <div className="relative flex items-center justify-between h-16">
          <div className="absolute inset-y-0 left-0 flex items-center">
            <BurguerMenu onToggle={onToggle} />
          </div>
          <div className="flex-1 flex items-stretch justify-center sm:justify-start">
            <div className="flex-shrink-0 flex items-center">
              <a className="text-link-white lg:block w-auto sm:ml-10" href="#">
                <img alt="logo" src="/logo.png" className="inline-block align-top h-20 w-20" />
              </a>
            </div>
            <div className="hidden self-center sm:block sm:ml-6">
              <div className="flex space-x-4">
                <a className="text-link-white" href="#">
                  <span className="text-5xl font-bold text-dark">{t.PRODUTOR_TITLE}</span>
                  <span className="text-5xl font-bold text-orange-chocolate m-0">
                    {t.CARD_TITLE}
                  </span>
                  <span className="text-2xl text-green-eveningSea mr-3 ml-3">-</span>
                  <span className="text-2xl font-semibold text-green-eveningSea">{t.PARTNER}</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

const BurguerMenu = ({ onToggle }: { readonly onToggle: () => void }): JSX.Element => {
  return (
    <div className="block">
      <button
        onClick={onToggle}
        id="nav-toggle"
        className="flex items-center pr-2 py-2 rounded text-gray-100 hover:text-white hover:border-white focus:outline-none">
        <BurguerMenuIcon color="white" />
      </button>
    </div>
  );
};

export default HeaderResponsive;
